import React from 'react';
import { Modal as SemanticModal } from 'semantic-ui-react';

import type { FC, ReactNode } from 'react';
import type { ModalProps as SemanticModalProps } from 'semantic-ui-react';

import * as styles from './Modal.style';

interface ModalProps extends SemanticModalProps {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
}

const Modal: FC<ModalProps> = ({ children, header, footer, ...props }) => {
  return (
    <SemanticModal {...props} style={styles.modal}>
      {header ? <SemanticModal.Header style={styles.header}>{header}</SemanticModal.Header> : null}
      <SemanticModal.Content style={styles.content}>{children}</SemanticModal.Content>
      {footer ? <SemanticModal.Actions style={styles.footer}>{footer}</SemanticModal.Actions> : null}
    </SemanticModal>
  );
};

export default Modal;
