import React from 'react';
import { Card as SemanticCard } from 'semantic-ui-react';

import type { FC } from 'react';
import type { CardProps as SemanticCardProps } from 'semantic-ui-react';

import './Card.css';

const Card: FC<SemanticCardProps> = (props) => {
  return <SemanticCard className="eeedoCard" {...props} />;
};

export default Card;
