import React from 'react';
import { Progress } from 'semantic-ui-react';

import type { ComponentProps, FC } from 'react';

import './ProgressBar.css';

type ProgressBarProps = ComponentProps<typeof Progress>;

const ProgressBar: FC<ProgressBarProps> = ({ className, ...props }) => (
  <Progress className={['eeedoProgressBar', className].filter(Boolean).join(' ')} {...props} />
);

export default React.memo(ProgressBar);
