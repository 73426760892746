import { defaultDropAnimationSideEffects, DragOverlay } from '@dnd-kit/core';
import React from 'react';
import ReactDOM from 'react-dom';

import type { DropAnimation } from '@dnd-kit/core';
import type { PropsWithChildren } from 'react';

export function SortableOverlay({ children }: PropsWithChildren) {
  const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: '0.4'
        }
      }
    })
  };
  return (
    <>
      {ReactDOM.createPortal(
        <DragOverlay
          style={{
            position: 'fixed',
            zIndex: 1050,
            pointerEvents: 'none'
          }}
          dropAnimation={dropAnimationConfig}
        >
          {children}
        </DragOverlay>,
        document.body
      )}
    </>
  );
}
