import { orderBy } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from 'react-i18next';
import { Accordion, Grid, Icon, Radio } from 'semantic-ui-react';

import type {
  Category,
  Channel,
  PersonalData,
  Priority,
  TabFilter,
  Tag,
  TicketType,
  TicketTypeMetadata,
  UserWithProfile
} from '@eeedo/types';
import type { TFunction } from 'i18next';
import type { FC } from 'react';
import type { WithTranslation } from 'react-i18next';
import type { StrictDropdownProps } from 'semantic-ui-react';

import DateSelector, { timestampPrefixToDate } from '../Case/DateSelector';
import { ArrayDropdownFilter, StringDropdownFilter } from '../Filter/DropdownFilter';
import Input from '../generic/Input/Input';
import * as styles from './Filter.style';
import LastContactAddressFilter from './LastContactAddressFilter';
import OriginalContactFilter from './OriginalContactFilter';
import OriginalDirectionDropdown from './OriginalDirectionDropdown';
import { filterTagsShownToUser } from 'src/Utilities/tags';

import type { ContentTypesFields } from 'src/types/Ticket';

import 'react-dates/lib/css/_datepicker.css';

export type LocalFiltersState = {
  titleFilter?: string;
  originalContactFilter?: string;
  lastContactAddressFilter?: string;
};

export interface IProps extends WithTranslation {
  ticketTypesMetadata: TicketTypeMetadata[];
  categories: Category[];
  personalData: PersonalData;
  ticketTypes: TicketType[];
  channels: Channel[];
  tags: Tag[];
  usersList: UserWithProfile[];
  priorities: Priority[];
  filters: TabFilter;
  contentType?: ContentTypesFields;
  asEditor?: boolean;
  isExtraOptionsOpen: boolean;
  localFiltersState: LocalFiltersState;
  prefixId: string;
  changeFilter: (value: any, type: keyof TabFilter) => void;
  onChangeFilter: (filter: FilterParams, prop: keyof LocalFiltersState, value: string) => void;
  toggleExtraOptions: () => void;
}

type FilterParams = 'title' | 'originalContact' | 'lastContactAddress';

const OFFSET = 100;

const Filter: FC<IProps> = (props) => {
  const paramsHeightRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const maxHeight = window.innerHeight - (paramsHeightRef.current?.getBoundingClientRect()?.top ?? 0);
    if (maxHeight && maxHeight > OFFSET) {
      setHeight(maxHeight - OFFSET);
    }
  }, [paramsHeightRef.current, props.filters, props.isExtraOptionsOpen]);
  const ticketTypesToOptions = (ticketTypes: TicketType[]): StrictDropdownProps['options'] => {
    const options = ticketTypes.map(({ name }) => ({ text: name, value: name }));
    return orderBy(options, ['text', 'value']);
  };

  const channelOptions = (channels: Channel[], t: TFunction) => {
    const options = channels.map((channel) => ({
      text: t([`CHANNEL_${channel.channel.toUpperCase()}`, channel.channel]),
      value: channel.id,
      icon: channel.icon || 'question circle'
    }));

    return orderBy(options, ['text', 'value']);
  };

  const tagsOptions = (tags: Tag[]) => {
    const options = filterTagsShownToUser(tags, props.personalData.ticketTypes).map((x) => ({
      text: x.name,
      value: x.id
    }));

    return orderBy(options, ['text', 'value']);
  };

  const { t, filters } = props;
  const possibleDelegates = props.usersList
    .filter((user) => user.role.id !== 'ROL3')
    .map((user) => ({
      text: `${((user || {}).profile || {}).firstName} ${((user || {}).profile || {}).lastName} `,
      value: user.UID
    }));

  const possibleDelegatesSorted = orderBy(possibleDelegates, ['text', 'value']);

  const priorities = props.priorities.map((priority) => {
    return { text: t(priority.text), value: priority.value };
  });

  const dateFilters: {
    type: keyof TabFilter;
    label: string;
  }[] = [
    { type: 'dueDateAfter', label: t('SEARCH_BY_DUEDATE_AFTER_DATE_RANGE') },
    { type: 'dueDateBefore', label: t('SEARCH_BY_DUEDATE_BEFORE_DATE_RANGE') },
    { type: 'touchedAfter', label: t('SEARCH_BY_TOUCHED_AFTER_DATE_RANGE') },
    { type: 'touchedBefore', label: t('SEARCH_BY_TOUCHED_BEFORE_DATE_RANGE') },
    { type: 'createdAfter', label: t('SEARCH_BY_CREATED_AFTER_DATE_RANGE') },
    { type: 'createdBefore', label: t('SEARCH_BY_CREATED_BEFORE_DATE_RANGE') }
  ];

  return (
    <Grid stackable columns={props.asEditor ? 2 : 1}>
      <div ref={paramsHeightRef} />
      <Grid.Column>
        <Scrollbars autoHide style={{ height: `${height}px` }}>
          <Grid.Column>
            <ArrayDropdownFilter
              id={'FILTER_BY_TICKETTYPE'}
              options={ticketTypesToOptions(props.ticketTypes)}
              value={filters.taskType}
              placeholder={t('FILTER_BY_TICKETTYPE')}
              onChange={(value) => props.changeFilter(value, 'taskType')}
              style={styles.defaultMargin}
            />
            <ArrayDropdownFilter
              id={'FILTER_BY_CHANNEL'}
              options={channelOptions(
                props.channels.filter((channel) => channel.active && props.personalData.channels.includes(channel.id)),
                t
              )}
              value={filters.channel}
              placeholder={t('FILTER_BY_CHANNEL')}
              onChange={(value) => props.changeFilter(value, 'channel')}
              style={styles.defaultMargin}
            />
            <ArrayDropdownFilter
              id={'FILTER_BY_TAG'}
              options={tagsOptions(props.tags)}
              value={filters.tags}
              placeholder={t('FILTER_BY_TAG')}
              onChange={(value) => props.changeFilter(value, 'tags')}
              style={styles.defaultMargin}
            />
            <ArrayDropdownFilter
              id={'FILTER_BY_TAG_CATEGORY'}
              options={props.categories
                .filter((category) => !!category.tags.length)
                .map((category) => ({ text: category.name, value: category.id }))}
              value={filters.categories}
              placeholder={t('FILTER_BY_TAG_CATEGORY')}
              onChange={(value) => {
                props.changeFilter(value, 'categories');
              }}
              style={styles.defaultMargin}
            />
            <Input
              id="FILTER_BY_TITLE"
              placeholder={t('FILTER_BY_TITLE')}
              icon="search"
              value={props.localFiltersState.titleFilter || ''}
              onChange={(e) => props.onChangeFilter('title', 'titleFilter', e.target.value)}
              fluid
              className="filterInputHover"
            />

            <br />

            <Radio
              id={`${props.prefixId}FILTER_BY_WITHIN_TODAY`}
              fluid={true}
              toggle
              label={t('FILTER_BY_WITHIN_TODAY')}
              checked={filters.dueDateToday || false}
              onClick={(e, data) => {
                props.changeFilter(data.checked || '', 'dueDateToday');
              }}
              style={styles.filterByWithinToday}
            />
            <br />
            <Radio
              id={`${props.prefixId}FILTER_BY_ME`}
              fluid={true}
              toggle
              label={t('FILTER_BY_ME')}
              checked={filters.handledByMe || false}
              onClick={(e, data) => {
                props.changeFilter(data.checked || '', 'handledByMe');
              }}
              style={styles.filterByMe}
            />
            <br />
            <Radio
              id={`${props.prefixId}FILTER_BY_ME_OR_NOONE`}
              fluid={true}
              toggle
              label={t('FILTER_BY_ME_OR_NOONE')}
              checked={filters.handledByMeOrNoOne || false}
              onClick={(e, data) => {
                props.changeFilter(data.checked !== true ? '' : true, 'handledByMeOrNoOne');
              }}
              style={{ marginLeft: 'auto', marginTop: '.75em' }}
            />
            <br />

            <Radio
              id={`${props.prefixId}FILTER_BY_DELEGATED_TO_ME`}
              fluid={true}
              toggle
              label={t('FILTER_BY_DELEGATED_TO_ME')}
              checked={filters.delegatedToMe || false}
              onClick={(e, data) => {
                props.changeFilter(data.checked || '', 'delegatedToMe');
              }}
              style={styles.radioMargins}
            />
            <br />
            <Radio
              id={`${props.prefixId}FILTER_NON_DELEGATED`}
              fluid={true}
              toggle
              label={t('FILTER_NON_DELEGATED')}
              checked={filters.notDelegated || false}
              onClick={(e, data) => {
                props.changeFilter(data.checked || '', 'notDelegated');
              }}
              style={styles.radioMargins}
            />
            <br />
            <Radio
              id={`${props.prefixId}FILTER_BY_WITHIN_24H_DUE_DATE`}
              fluid={true}
              toggle
              label={t('FILTER_BY_WITHIN_24H_DUE_DATE')}
              checked={filters.dueDate24h || false}
              onClick={(e, data) => {
                props.changeFilter(data.checked || '', 'dueDate24h');
              }}
              style={styles.radioMargins}
            />
            <br />
            <Radio
              id={`${props.prefixId}FILTER_BY_DUE_DATE_IS_OVER`}
              fluid={true}
              toggle
              label={t('FILTER_BY_DUE_DATE_IS_OVER')}
              checked={filters.dueDateOver || false}
              onClick={(e, data) => {
                props.changeFilter(data.checked || '', 'dueDateOver');
              }}
              style={styles.radioMargins}
            />
          </Grid.Column>
          <Grid.Column>
            <Accordion>
              {props.asEditor ? null : (
                <Accordion.Title active={props.isExtraOptionsOpen} onClick={() => props.toggleExtraOptions()}>
                  <Icon name="dropdown" id="SHOW_ADDITIONAL_FILTERS" />
                  {t('SHOW_ADDITIONAL_FILTERS')}
                </Accordion.Title>
              )}

              <Accordion.Content active={props.isExtraOptionsOpen}>
                <ArrayDropdownFilter
                  id={'GENERAL_SEARCH_NO_RESULTS_FILTER_BY_DELEGATE'}
                  options={possibleDelegatesSorted}
                  value={filters.delegates}
                  placeholder={t('FILTER_BY_DELEGATE')}
                  onChange={(value) => props.changeFilter(value, 'delegates')}
                  style={styles.defaultMargin}
                />
                <StringDropdownFilter
                  id={'GENERAL_SEARCH_NO_RESULTS_FILTER_BY_LAST_HANDLED_USER'}
                  options={possibleDelegatesSorted}
                  value={filters.handledBy}
                  placeholder={t('FILTER_BY_LAST_HANDLED_USER')}
                  onChange={(value) => props.changeFilter(value, 'handledBy')}
                  style={styles.defaultMargin}
                />
                <StringDropdownFilter
                  id="GENERAL_SEARCH_NO_RESULTS_FILTER_BY_CREATED_USER"
                  options={possibleDelegatesSorted}
                  value={filters.createdBy}
                  placeholder={t('FILTER_BY_CREATED_USER')}
                  onChange={(value) => props.changeFilter(value, 'createdBy')}
                  style={styles.defaultMargin}
                />
                <ArrayDropdownFilter
                  id={'GENERAL_SEARCH_NO_RESULTS_FILTER_BY_PRIORITIES'}
                  options={priorities}
                  value={filters.priorities}
                  placeholder={t('FILTER_BY_PRIORITIES')}
                  onChange={(value) => props.changeFilter(value, 'priorities')}
                  style={styles.defaultMargin}
                />
                {dateFilters.map((filter, index) => (
                  <div key={`date-filter-${index}`} style={styles.defaultMargin}>
                    <DateSelector
                      value={filters[filter.type]}
                      onChange={(timestamp) => {
                        props.changeFilter(timestampPrefixToDate(timestamp), filter.type);
                      }}
                      textRight={filter.label}
                    />
                  </div>
                ))}
                <Radio
                  id={`${props.prefixId}FILTER_NO_TAGS`}
                  toggle
                  label={t('FILTER_NO_TAGS')}
                  checked={!!filters.tagsNO}
                  onClick={(e, data) => {
                    props.changeFilter(data.checked || '', 'tagsNO');
                  }}
                  style={styles.defaultMargin}
                />
                <ArrayDropdownFilter
                  id={'GENERAL_SEARCH_NO_RESULTS_FILTER_BY_TAGS_BY_AND'}
                  options={tagsOptions(props.tags)}
                  value={filters.tagAND}
                  placeholder={t('FILTER_BY_TAGS_BY_AND')}
                  onChange={(value) => props.changeFilter(value, 'tagAND')}
                  sidePopup={t('FILTER_BY_TAGS_BY_AND_HELP')}
                  disabled={filters.tagsNO}
                  style={styles.defaultMargin}
                />
                <ArrayDropdownFilter
                  id={'GENERAL_SEARCH_NO_RESULTS_FILTER_BY_TAGS_BY_NOT'}
                  options={tagsOptions(props.tags)}
                  value={filters.tagNOT}
                  placeholder={t('FILTER_BY_TAGS_BY_NOT')}
                  onChange={(value) => props.changeFilter(value, 'tagNOT')}
                  sidePopup={t('FILTER_BY_TAGS_BY_NOT_HELP')}
                  disabled={filters.tagsNO}
                  style={styles.defaultMargin}
                />
                {props.contentType !== 'infopages' && (
                  <>
                    <OriginalContactFilter
                      tr={t}
                      originalContactFilter={props.localFiltersState.originalContactFilter}
                      onChangeFilter={(e) =>
                        props.onChangeFilter('originalContact', 'originalContactFilter', e.target.value)
                      }
                      style={styles.defaultMargin}
                    />
                    <LastContactAddressFilter
                      lastContactAddressFilter={props.localFiltersState.lastContactAddressFilter}
                      onChangeFilter={(e) =>
                        props.onChangeFilter('lastContactAddress', 'lastContactAddressFilter', e.target.value)
                      }
                      style={styles.defaultMargin}
                    />
                    <OriginalDirectionDropdown
                      filters={filters}
                      changeFilter={props.changeFilter}
                      style={styles.defaultMargin}
                    />
                  </>
                )}
                <ArrayDropdownFilter
                  id="FILTER_BY_NOT_TICKETTYPE"
                  options={ticketTypesToOptions(props.ticketTypes)}
                  value={filters.taskTypeNOT}
                  placeholder={t('FILTER_BY_NOT_TICKETTYPE')}
                  onChange={(value) => props.changeFilter(value, 'taskTypeNOT')}
                  style={styles.defaultMargin}
                />
                <ArrayDropdownFilter
                  id="FILTER_BY_NOT_TAG_CATEGORIES"
                  options={props.categories
                    .filter((category) => !!category.tags.length)
                    .map((category) => ({ text: category.name, value: category.id }))}
                  value={filters.tagCategoriesNOT}
                  placeholder={t('FILTER_NO_TAG_CATEGORIES')}
                  onChange={(value) => props.changeFilter(value, 'tagCategoriesNOT')}
                  style={styles.defaultMargin}
                />
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Scrollbars>
      </Grid.Column>
    </Grid>
  );
};

export default withTranslation('translations')(Filter);
