import React from 'react';
import { Accordion, AccordionTitle } from 'semantic-ui-react';

import type { ComponentProps, FC } from 'react';

import SingleLineAccordionTab from './SingleLineAccordionTab';
import { SingleLineTabProvider } from './SingleLineTabProvider';
import TableTabMenage from './TableTabMenage';
import Section from 'src/Components/generic/Section/Section';

import type { TicketListTicket } from 'src/types/Ticket';

import './SingleLineTicketView.css';

import SingleLineActionProgress from './SingleLineActionProgress';
import SingleLineActions from './SingleLineActions';

import type Dropdown from 'src/Components/generic/Dropdown/Dropdown';

export type AccordionTab = {
  index: number;
  status: 'draft' | 'inReview' | 'waitingToBePublished' | 'published' | 'waste' | 'todo' | 'doing' | 'done';
  tickets: TicketListTicket[];
};

interface SingleLineTicketViewContentProps {
  accordionTabs: AccordionTab[];
  dropdownOptions: ComponentProps<typeof Dropdown>['options'];
}

const SingleLineTicketViewContent: FC<SingleLineTicketViewContentProps> = ({ accordionTabs, dropdownOptions }) => {
  return (
    <SingleLineTabProvider>
      <Accordion fluid styled style={{ borderRadius: 0, flex: 1, height: '100%' }}>
        <AccordionTitle>
          <Section justify="flex-end" gap={12}>
            <TableTabMenage />
            <SingleLineActions options={dropdownOptions} />
            <SingleLineActionProgress />
          </Section>
        </AccordionTitle>
        {accordionTabs.map((tab) => (
          <SingleLineAccordionTab index={tab.index} status={tab.status} tickets={tab.tickets} />
        ))}
      </Accordion>
    </SingleLineTabProvider>
  );
};

export default SingleLineTicketViewContent;
