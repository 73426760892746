import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { AutoSizer, Column, Table as VirtualizedTable } from 'react-virtualized';
import { AccordionContent, Checkbox, Icon, Label } from 'semantic-ui-react';

import type { ConnectedProps } from 'react-redux';
import type { TableCellProps } from 'react-virtualized';
import type { SemanticICONS } from 'semantic-ui-react';

import * as styles from './SingleLineAccordionTab.style';
import { useSingleLineTabContext } from './SingleLineTabProvider';
import AccordionHeader from 'src/Components/Case/AccordionHeader';
import WorkStatusImageContainer from 'src/containers/WorkStatusImageContainer';
import { setSelectedTicket } from 'src/reducers/singleLineViewReducer';
import { StaticTabs } from 'src/types/TicketList';
import { channelIconsMap, statusTranslationsMap } from 'src/Utilities/constants';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';

import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

interface SingleLineAccordionTabProps extends ConnectedProps<typeof connector> {
  index: number;
  status: keyof typeof statusTranslationsMap;
  tickets: TicketListTicket[];
}

const SingleLineAccordionTab: React.FC<SingleLineAccordionTabProps> = ({
  index,
  status,
  tickets,
  activeIndex,
  priorities,
  user,
  userData,
  selected
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tabsData } = useSingleLineTabContext();

  const onCheckboxChange = (id: string, checked: boolean) => {
    dispatch(setSelectedTicket({ id, checked }));
  };

  const renderCheckboxCell = ({ rowData }: TableCellProps) => (
    <Checkbox checked={!!selected[rowData.id]} onChange={(_, data) => onCheckboxChange(rowData.id, data.checked!)} />
  );
  const commonColumnProps = () => ({
    headerStyle: styles.headerCell,
    style: styles.columnCell
  });

  // Config for rendering columns
  const columnConfig = {
    taskType: {
      label: 'Task Type',
      width: 200,
      flexGrow: 1,
      flexShrink: 1,
      cellRenderer: ({ cellData }: TableCellProps) => <Label>{cellData}</Label>
    },
    title: {
      label: 'Title',
      width: 300,
      flexGrow: 2,
      flexShrink: 1,
      cellRenderer: ({ cellData }: TableCellProps) => <div>{cellData}</div>
    },
    created: {
      label: 'Created',
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ cellData }: TableCellProps) => (
        <div>{getPrettyDate(cellData, { format: DATE_TIME_FORMAT }) || '-'}</div>
      )
    },
    dueDate: {
      label: 'Due Date',
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ cellData }: TableCellProps) => (
        <div>{getPrettyDate(cellData, { format: DATE_TIME_FORMAT }) || '-'}</div>
      )
    },
    channel: {
      label: 'Channel',
      width: 100,
      flexGrow: 0,
      flexShrink: 0,
      cellRenderer: ({ cellData }: TableCellProps) => (
        <div style={styles.channelIconWrapper}>
          <Icon name={channelIconsMap[cellData]} />
        </div>
      )
    },
    priority: {
      label: 'Priority',
      width: 200,
      flexGrow: 1,
      flexShrink: 1,
      cellRenderer: ({ cellData }: TableCellProps) => {
        const priority = priorities.find((p) => p.value === cellData);
        const iconStyle = { color: priority?.color };
        return (
          priority && (
            <div style={styles.priorityIconWrapper}>
              <Icon name={priority.icon as SemanticICONS} style={iconStyle} />
              {t(priority.text)}
            </div>
          )
        );
      }
    },
    actions: {
      label: 'Actions',
      width: 100,
      flexGrow: 0,
      flexShrink: 0,
      cellRenderer: ({ rowData }: TableCellProps) => (
        <WorkStatusImageContainer
          user={user}
          id={rowData.id}
          userData={userData}
          showStartWorkingOnButton={rowData.status === 'todo'}
        />
      )
    }
  };

  return (
    <AccordionHeader
      as="h4"
      title={t(statusTranslationsMap[status])}
      label={tickets.length}
      active={activeIndex === index}
      divider={false}
    >
      <AccordionContent style={{ padding: '0 24px' }} active={activeIndex === index}>
        <div style={styles.tableWrapper}>
          <AutoSizer>
            {({ width, height }) => (
              <VirtualizedTable
                width={width}
                height={height}
                headerHeight={44}
                rowHeight={52}
                rowCount={tickets.length}
                rowGetter={({ index }) => tickets[index]}
              >
                <Column
                  dataKey="checkbox"
                  width={25}
                  flexShrink={0}
                  flexGrow={0}
                  cellRenderer={renderCheckboxCell}
                  {...commonColumnProps()}
                />
                {tabsData
                  .filter((tab) => tab.show)
                  .map((tab) => {
                    const config = columnConfig[tab.dataKey];
                    if (!config) return null;
                    return (
                      <Column
                        key={tab.dataKey}
                        label={t(config?.label)}
                        dataKey={tab.dataKey}
                        width={config.width}
                        flexGrow={config.flexGrow}
                        flexShrink={config.flexShrink}
                        cellRenderer={config.cellRenderer}
                        headerStyle={styles.headerCell}
                        style={styles.columnCell}
                      />
                    );
                  })}
              </VirtualizedTable>
            )}
          </AutoSizer>
        </div>
      </AccordionContent>
    </AccordionHeader>
  );
};

const connector = connect((state: State) => {
  const activeTab = Object.values(state.ticketListTabs).find((tab) => tab.activeTab);

  return {
    activeIndex: state.ticketListTabs[activeTab?.id ?? StaticTabs.MAIN_VIEW].accordionIndex,
    priorities: state.ticketPriorities,
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID)!,
    userData: state.userData,
    selected: state.singleLineView.selected
  };
});

export default connector(SingleLineAccordionTab);
