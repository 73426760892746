export const modal: React.CSSProperties = {
  boxShadow: 'none',
  borderRadius: '32px'
};

export const header: React.CSSProperties = {
  padding: '32px 32px 14px',
  borderRadius: '32px 32px 0 0'
};

export const content: React.CSSProperties = {
  borderRadius: '0 0 32px 32px',
  padding: '14px 32px'
};

export const footer: React.CSSProperties = {
  padding: '14px 32px',
  borderRadius: '0 0 32px 32px',
  backgroundColor: 'white'
};
