import { createAsyncThunk } from '@reduxjs/toolkit';

import { updateTicket } from './ticketsActions';
import { resetSelectedTickets, setInProgress } from 'src/reducers/singleLineViewReducer';

import type { State } from 'src/types/initialState';
import type { Ticket } from 'src/types/Ticket';

export const updateSelectedTasks = createAsyncThunk<void, Partial<Ticket>, { state: State }>(
  'singleLineView/updateSelectedTasks',
  async (update, { dispatch, getState }) => {
    const selected = getState().singleLineView.selected;

    const promises = [];

    for (const id in selected) {
      if (selected[id]) {
        dispatch(setInProgress({ id, inProgress: true }));

        promises.push(
          dispatch(updateTicket({ id, ticket: update, showTosat: false })).then(() => {
            dispatch(setInProgress({ id, inProgress: false }));
          })
        );
      }
    }

    await Promise.all(promises).then(() => {
      dispatch(resetSelectedTickets());
    });
  }
);
