import React from 'react';

import './Section.css';

interface SectionProps {
  children: React.ReactNode;
  display?: 'flex' | 'inline-flex' | 'grid';
  direction?: 'row' | 'column';
  gap?: number;
  padding?: string;
  margin?: string;
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  columns?: number;
  className?: string;
  style?: React.CSSProperties;
}

const Section: React.FC<SectionProps> = ({
  children,
  display = 'flex',
  direction = 'row',
  gap,
  padding,
  margin,
  align = 'stretch',
  justify = 'flex-start',
  wrap = 'nowrap',
  columns = 1,
  className = '',
  style
}) => {
  const sectionClasses = [
    'section',
    `section--${display}`,
    `section--direction-${direction}`,
    `section--align-${align}`,
    `section--justify-${justify}`,
    `section--wrap-${wrap}`,
    display === 'grid' ? `section--columns-${columns}` : '',
    className
  ]
    .filter(Boolean)
    .join(' ');

  const styleInner = {
    ...(gap && { gap: `${gap}px` }),
    ...(padding && { padding: padding }),
    ...(margin && { margin: margin }),
    ...style
  };

  return (
    <section className={sectionClasses} style={styleInner}>
      {children}
    </section>
  );
};

export default React.memo(Section);
