import React, { createContext, useContext, useEffect, useState } from 'react';

export interface Tab {
  id: number;
  title: string;
  dataKey: string;
  show: boolean;
}

const SingleLineTabContext = createContext<{
  tabsData: Tab[];
  resetTabs: () => void;
  setTabsData: React.Dispatch<React.SetStateAction<Tab[]>>;
}>({
  tabsData: [],
  resetTabs: () => {},
  setTabsData: () => {}
});

export const useSingleLineTabContext = () => useContext(SingleLineTabContext);

enum Key {
  SingleLineTabs = 'SingleLineTabs'
}
const defaultTabs: Tab[] = [
  { id: 0, title: 'Task Type', dataKey: 'taskType', show: true },
  { id: 1, title: 'Title', dataKey: 'title', show: true },
  { id: 2, title: 'Created', dataKey: 'created', show: true },
  { id: 3, title: 'Due Date', dataKey: 'dueDate', show: true },
  { id: 4, title: 'Channel', dataKey: 'channel', show: true },
  { id: 5, title: 'Priority', dataKey: 'priority', show: true },
  { id: 6, title: 'Actions', dataKey: 'actions', show: true }
];

export const SingleLineTabProvider = ({ children }: { children: React.ReactNode }) => {
  const [tabsData, setTabsData] = useState<Tab[]>([...defaultTabs]);

  const handleResetTabs = () => setTabsData([...defaultTabs]);

  useEffect(() => {
    const savedTabsData = localStorage.getItem(Key.SingleLineTabs);
    if (savedTabsData) {
      setTabsData(JSON.parse(savedTabsData) ?? [...defaultTabs]);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(Key.SingleLineTabs, JSON.stringify(tabsData));
  }, [tabsData]);

  return (
    <SingleLineTabContext.Provider
      value={{
        tabsData,
        setTabsData,
        resetTabs: () => handleResetTabs()
      }}
    >
      {children}
    </SingleLineTabContext.Provider>
  );
};
