import React, { useCallback, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import type { TabFilter } from '@eeedo/types';
import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import SingleLineTicketViewContent from './SingleLineTicketViewContent';
import { StaticTabs } from 'src/types/TicketList';
import { doneTicketsOrdering, filterTicketList, ticketOrdering } from 'src/Utilities/ticketList';

import type { AccordionTab } from './SingleLineTicketViewContent';
import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

import './SingleLineTicketView.css';

import { useTranslation } from 'react-i18next';

import type { StrictDropdownItemProps } from 'semantic-ui-react';

import { updateSelectedTasks } from 'src/actions/singleLineViewActions';
import { setSinglineLineViewAction } from 'src/reducers/singleLineViewReducer';

type SingleLineTicketViewProps = ConnectedProps<typeof connector>;

const SingleLineTicketView: FC<SingleLineTicketViewProps> = ({
  ticketListTab,
  categories,
  personalData,
  usersList
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = ticketListTab.filters ?? ({} as TabFilter);
  const { tickets: contents, sorting, direction } = ticketListTab;
  const orderedTickets = ticketOrdering(contents, sorting, direction, personalData.userCustomTicketOrdering);
  const filterArgs = {
    categories,
    personalData,
    filters,
    usersList
  };

  const todoTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        status: 'todo',
        tickets: orderedTickets.filter((ticket) => ticket.status === 'todo')
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );
  const doingTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        status: 'doing',
        tickets: orderedTickets.filter((ticket) => ticket.status === 'doing')
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );
  const doneTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        tickets: doneTicketsOrdering(ticketListTab.tickets.filter((ticket) => ticket.status === 'done')),
        status: 'done'
      }) as TicketListTicket[],
    [categories, personalData, filters, contents, usersList]
  );

  const accordionTabs: AccordionTab[] = useMemo(
    () => [
      { index: 0, status: 'todo', tickets: todoTickets },
      { index: 1, status: 'doing', tickets: doingTickets },
      { index: 2, status: 'done', tickets: doneTickets }
    ],
    [todoTickets, doingTickets, doneTickets]
  );

  const onOptionClick = useCallback<NonNullable<StrictDropdownItemProps['onClick']>>((_, { text, update }) => {
    dispatch(setSinglineLineViewAction(text as string));
    dispatch(updateSelectedTasks(update));
  }, []);

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('Deprecate'),
        value: 'deprecate',
        onClick: onOptionClick,
        update: { deprecated: true }
      },
      {
        text: t('Set to todo'),
        value: 'setToTodo',
        onClick: onOptionClick,
        update: { status: 'todo' }
      },
      {
        text: t('Set to doing'),
        value: 'setToDoing',
        onClick: onOptionClick,
        update: { status: 'doing' }
      },
      {
        text: t('Set to done'),
        value: 'setToDone',
        onClick: onOptionClick,
        update: { status: 'done' }
      }
    ],
    [onOptionClick, t]
  );

  return <SingleLineTicketViewContent accordionTabs={accordionTabs} dropdownOptions={dropdownOptions} />;
};

const connector = connect((state: State) => {
  const activeTab = Object.values(state.ticketListTabs).find((tab) => tab.activeTab);

  return {
    categories: state.categories,
    personalData: state.userData,
    usersList: state.usersList.usersList,
    ticketListTab: state.ticketListTabs[activeTab?.id ?? StaticTabs.MAIN_VIEW]
  };
});

export default withRouter(connector(SingleLineTicketView));
